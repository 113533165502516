import imageNextBurn from "../public/img/tiles/next-burn.jpg";
import imageBioStrength from "../public/img/tiles/biostrength.jpg";
import imageyoga from "../public/img/tiles/next-yoga.jpg";
import imageGym from "../public/img/tiles/gym.jpg";
import imageHotYoga from "../public/img/tiles/hot-yoga.jpg";
import imageNextBooty from "../public/img/tiles/next-booty.jpg";
import imageLesMills from "../public/img/tiles/les-mills.jpg";
import imageNextBattle from "../public/img/tiles/next-battle.jpg";
import imageNextBoost from "../public/img/tiles/next-boost.jpg";

export const concepts = [
  {
    link: "/next-burn",
    image: imageNextBurn,
    title: "Next.Burn",
    video: "/videos/concepts/next-burn.mp4",
    locations: ["HOLESOVICE"],
    available: true,
  },
  {
    link: "/gym",
    image: imageGym,
    title: "Gym 4.0",
    video: "/videos/concepts/gym.mp4",
    locations: ["HOLESOVICE", "VINOHRADY", "KARLIN", "SMICHOV"],
    available: true,
  },
  {
    link: "/yoga",
    image: imageyoga,
    title: "Yoga",
    video: "/videos/concepts/next-yoga.mp4",
    locations: ["HOLESOVICE", "KARLIN", "VINOHRADY"],
    available: true,
  },
  {
    link: "/next-booty",
    image: imageNextBooty,
    title: "Next.Booty",
    video: "/videos/concepts/next-booty.mp4",
    locations: ["VINOHRADY"],
    available: true,
  },
  {
    link: "/les-mills",
    image: imageLesMills,
    title: "Les Mills",
    video: "/videos/concepts/les-mills.mp4",
    locations: ["VINOHRADY", "KARLIN"],
    available: true,
  },
  {
    link: "/hot-yoga",
    image: imageHotYoga,
    title: "Hot.Yoga",
    video: "/videos/concepts/hot-yoga.mp4",
    locations: ["KARLIN"],
    available: true,
  },
  {
    link: "/next-battle",
    image: imageNextBattle,
    title: "Next.Battle",
    video: "/videos/concepts/next-battle.mp4",
    locations: ["KARLIN"],
    available: true,
  },
  {
    link: "/next-boost",
    image: imageNextBoost,
    title: "Next.Boost",
    video: "/videos/concepts/next-boost.mp4",
    locations: ["SMICHOV"],
    available: true,
  },
  {
    link: "/biostrength",
    image: imageBioStrength,
    title: "BioStrength",
    video: "/videos/concepts/biostrength.mp4",
    locations: ["HOLESOVICE", "VINOHRADY", "KARLIN", "SMICHOV"],
    available: true,
  },
];

export const filterConcepts = (activeFilters, location) => {
  if (!activeFilters.location && !location) return concepts;

  return concepts.filter((concept) => {
    let show = false;

    if (activeFilters.location || location) {
      show =
        concept.locations.includes(activeFilters.location) ||
        concept.locations.includes(location);
    }

    return show;
  });
};

export const defaultConceptsFilters = {
  location: null,
};
