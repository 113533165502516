export default function SvgArrowLeft({id = 'svg-arrow-left', ...rest}) {

    return (
        <svg viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M10.2999 19.9004C10.921 20.1741 11.6456 19.9004 11.9303 19.3034C12.215 18.7064 11.9303 18.0099 11.3092 17.7362C10.6881 17.4626 9.96343 17.7362 9.67875 18.3333C9.39407 18.9303 9.67875 19.6268 10.2999 19.9004Z"
                fill="currentColor"/>
            <path
                d="M1.78572 11.791L1.8116 11.791L25 11.791L25 9.42783L4.27019 9.42783L12.3188 1.66664L10.5849 -3.08241e-05L0.0517599 10.1243L1.78572 11.791Z"
                fill="currentColor"/>
            <path opacity="0.6"
                  d="M11.7236 18.0345L9.98968 19.7012L2.84938e-05 10.1241L1.75986 8.45739L11.7236 18.0345Z"
                  fill={`url(#paint0_linear_963_547_left_${id})`}/>
            <defs>
                <linearGradient id={`paint0_linear_963_547_left_${id}`} x1="5.87063" y1="19.713" x2="5.87063"
                                y2="8.44697" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0158937" stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>

    )
}