import {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {EffectCards, Navigation} from "swiper";
import ConceptCard from "./ConceptCard";
import SvgArrowLeft from "../svg/SvgArrowLeft";
import SvgArrowRight from "../svg/SvgArrowRight";
import {concepts} from "../helpers/concepts";

SwiperCore.use([Navigation, EffectCards]);

export default function ConceptsCarousel({disabled, title, list, body, name, ...rest}) {
    const [showCarousel, setShowCarousel] = useState(false);
    const [slidesPerView, setSlidesPerView] = useState(1);
    const [activeIndex, setActiveIndex] = useState(null);

    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const carousel = (
        <Swiper
            className="w-full"
            navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            loop={typeof window !== "undefined" && window.innerWidth >= 768}
            spaceBetween={0}
            slidesPerView={slidesPerView}
            onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
            effect={typeof window !== "undefined" && window.innerWidth >= 768 ? 'slide' : 'cards'}
            cardsEffect={{
                slideShadows: false,
                rotate: false,
                perSlideRotate: 0,
                perSlideOffset: 30
            }}
            onSlideChange={(swiper) => {
                setIsFirstSlide(swiper.isBeginning);
                setIsLastSlide(swiper.isEnd);
            }}
        >
            {concepts.map((item, key) => {
                return (
                    <SwiperSlide key={`Feature: ${key}`} className="slide-scale">
                        <div className="py-7 px-3 transition-all">
                            <ConceptCard {...item}
                                         key={`ConceptTile: ${key}`}
                                         className="shrink-0 w-full bg-white max-w-[300px] md:max-w-none mx-auto"
                                         onMouseEnter={() => setActiveIndex(key)}
                                         onMouseOut={() => setActiveIndex(null)}
                                         active={activeIndex === key}
                            />
                        </div>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    );

    const breakpointChecker = () => {
        let slides = 1;

        if (window.innerWidth >= 1024) {
            slides = 3;
        } else if (window.innerWidth >= 768) {
            slides = 2;
        }

        setSlidesPerView(slides);
    }


    useEffect(() => {
        if (navigationPrevRef.current) {
            window?.addEventListener('resize', breakpointChecker);
            breakpointChecker();

            setShowCarousel(true);
        }

        return () => window?.removeEventListener('resize', breakpointChecker);
    }, [navigationPrevRef]);

    return (
        <>
            <div className="relative -mx-6 md:mx-0">
                {showCarousel && carousel}
            </div>


            <div className="text-center">
                <div
                    className="relative inline-flex -mt-8 translate-y-1/2 mx-auto divided z-20 items-stretch rounded-lg overflow-hidden shado2w-xl">
                    <button ref={navigationPrevRef}
                            className={`${isFirstSlide ? 'opacity-20 md:opacity-100' : ''} py-9 px-10 bg-white text-black mouse-hover:bg-black mouse-hover:text-white transition-colors will-change-transform duration-300`}>
                        <SvgArrowLeft width={25} id="concepts-carousel"/>
                    </button>
                    <button ref={navigationNextRef}
                            className={`${isLastSlide ? 'opacity-20 md:opacity-100' : ''} py-9 px-10 bg-white text-black mouse-hover:bg-black mouse-hover:text-white transition-colors will-change-transform duration-300`}>
                        <SvgArrowRight width={25} id="concepts-carousel"/>
                    </button>
                </div>
            </div>
        </>
    )
}
